import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

// Initialize Firebase
var config = {
    apiKey: "AIzaSyDwLv35vFCEgYAjbLem9kFzM1QDqOl8pts",
    authDomain: "audacieux-studio.firebaseapp.com",
    projectId: "audacieux-studio",
    storageBucket: "audacieux-studio.appspot.com",
    messagingSenderId: "667884198545",
    appId: "1:667884198545:web:3716fbf5ca2730e416bd30",
    measurementId: "G-C3L0ZYQGF1"
}; 

const firebaseApp = initializeApp(config);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);
const analytics = getAnalytics(firebaseApp);

export {db, storage, analytics}



// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyDwLv35vFCEgYAjbLem9kFzM1QDqOl8pts",
//   authDomain: "audacieux-studio.firebaseapp.com",
//   projectId: "audacieux-studio",
//   storageBucket: "audacieux-studio.appspot.com",
//   messagingSenderId: "667884198545",
//   appId: "1:667884198545:web:3716fbf5ca2730e416bd30",
//   measurementId: "G-C3L0ZYQGF1"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);