/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./MARQUEE.scss";
//PLUGINS
import Marquee from "react-fast-marquee";

class MARQUEE extends Component {

    /** Display content */
    _displayContent(direction,size,name){
        let unicode="";

        // size
        if(size === "big") unicode = "\u00A0";
        else unicode = "\u2003"

        // direction
        if(direction === "left") return unicode+"←"+unicode+name;
        else return name+unicode+"→"+unicode;
    }

	render(){
        let {direction, speed, size, name, extra} = this.props;
        return(
            <Marquee 
                direction={direction} 
                speed={speed}>
                <div className={'marquee-'+size+' --'+extra}>{this._displayContent(direction,size,name)}</div>
            </Marquee>
        );
    }
}

export default MARQUEE;

