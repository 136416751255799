/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./CREATIONS.scss";
//DATA
import DATA from "../../texts.json";
//COMPONENTS
import BADGE from "../../Components/BADGE/BADGE";
import BTN from "../../Components/BTN/BTN";
import CarouselWithRef from "../../Components/CAROUSEL/CAROUSEL";
import MARQUEE from "../../Components/MARQUEE/MARQUEE";
import TITLE from "../../Components/TITLE/TITLE";
import PROJECT_ITEM from "../../Components/PROJECT_ITEM/PROJECT_ITEM";

//SERVICES
import StringService from "../../Services/StringService";
const stringService = new StringService();


class CREATIONS extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedType : 0
        };
    }

    // Créer une référence pour le composant enfant
    carouselRef = React.createRef();

    /** Selected type filter */
    _onSelectType(typeNbr){
        this.setState({
            selectedType : typeNbr
        });
        this.carouselRef.current.refreshCarousel();
    }

    /** On click on project */
    _onSelectProject(prj){
        this.props.onSelectPrj(prj)
    }

    /** On selecting pic */
    _onSelectPicture(pic){
        this.props.onSelectPic(pic);
    }

    //------------------------------------------------------------//
	//------------------------ RENDER ----------------------------//
   //------------------------------------------------------------//

    /** Display the selected project badges */
    _displayTypes(selectedProject, selectedLang){
        let types = "";
        types = stringService.getSelectedPrjType(selectedProject, selectedLang);
        return types;
    }

    /** Display the selected project badges */
    _displayBadges(selectedProject){
        if(selectedProject['softwares']){
            return selectedProject['softwares'].map((soft) => {
                return(
                    <BADGE key={soft} type={soft}/>
                );
            })
        }
    }

    /** Display the selected project */
    _displaySelectedProject(selectedProject, selectedLang){
        let {selectedPic} = this.props;

        
        if(selectedProject !== undefined && selectedProject['lang'] !== undefined){
            return(
                <div className="creations-selected">
                    <div className="creations-selected-content">
                        <div className="creations-selected-content-img">
                            <MARQUEE direction="left" speed="20" size="small" name={selectedProject.lang[selectedLang].name} extra="black"/>
                            {selectedPic && (<div className="creations-selected-content-img__picture" style={{ backgroundImage: `url(${selectedPic["url"]})` }}></div>)}
                            <MARQUEE direction="right" speed="20" size="small" name={selectedProject.date} extra="white"/>
                        </div>
    
                        <div className="creations-selected-content-data">
                            <div className="creations-selected-content-data-texts">
                                <div className="creations-selected-data-texts-text">
                                {selectedProject.lang[selectedLang].desc}
                                </div>
                                <div className="creations-selected-content-data-texts-sections">
                                    {this._displayTypes(selectedProject, selectedLang)}
                                </div>
                                <div className="creations-selected-content-data-texts-badges">
                                    {this._displayBadges(selectedProject)}
                                </div>
                            </div>
                            {selectedProject['imgs'] && (
                                <div className="creations-selected-content-data-imgs">
                                    <PROJECT_ITEM key={selectedProject['imgs'][0]['id']} label="" img={selectedProject['imgs'][0]["url"]} onClick={() => this._onSelectPicture(selectedProject['imgs'][0])} mini={true}/>
                                    <PROJECT_ITEM key={selectedProject['imgs'][1]['id']} label="" img={selectedProject['imgs'][1]["url"]} onClick={() => this._onSelectPicture(selectedProject['imgs'][1])} mini={true}/>
                                    <PROJECT_ITEM key={selectedProject['imgs'][2]['id']} label="" img={selectedProject['imgs'][2]["url"]} onClick={() => this._onSelectPicture(selectedProject['imgs'][2])} mini={true}/>
                                </div>
                            )}
                        </div>
                    </div>
    
                    <div className="creations-selected-banner"></div>
                </div>
            );
        }
    }
	render(){
        let {projects, selectedProject, selectedLang} = this.props;
        let {selectedType} = this.state;

        return(
            <>
            {projects.length && selectedProject &&(
                <div className="creations">
                    <div className="creations-texts">
                        <div className="creations-texts-title">
                            <TITLE nbr="③" name={DATA.titles.creations[selectedLang]} />
                        </div>
                        <div className="creations-texts-text">
                            {DATA.texts.creations[selectedLang]}
                        </div>
                    </div>
                    <div className="creations-filters">
                        <BTN label={DATA.techs.dg[selectedLang]}         onClick={() => this._onSelectType(0)} selected={selectedType===0} />
                        <BTN label={DATA.techs.webdesign[selectedLang]}  onClick={() => this._onSelectType(1)} selected={selectedType===1}/>
                        {/* <BTN label="DÉVELOPPEMENT WEB & MOBILE" onClick={() => this._onSelectType(2)} selected={selectedType===2}/> */}
                    </div>

                    <CarouselWithRef ref={this.carouselRef} projects={projects} onSelect={(prj) => this._onSelectProject(prj)} selectedType={selectedType} selectedLang={selectedLang}/>

                    {this._displaySelectedProject(selectedProject, selectedLang)}
                </div>
            )}
            </>
        );
    }
}

export default CREATIONS;

