/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./BADGE.scss";

class BADGE extends Component {

    constructor(props) {
        super(props);
        this.state = {
            background : "grey",
            title : ""
        };
    }

    componentDidMount(){
        let {type} = this.props;
        this._renderData(type);
    }

    /** Render text */
    _renderData(type){
        let _background = "grey";
        let _title = "Inconnu";

        if(type == 0){
            _background = "#E1F4FF";
            _title = "ADOBE PHOTOSHOP";
        }else if (type == 1){
            _background = "#FFEBCC";
            _title = "ADOBE ILLUSTRATOR";
        }
        else if (type == 2){
            _background = "#FFBBBB";
            _title = "ADOBE INDESIGN";
        }
        else if (type == 3){
            _background = "#FFCFEF";
            _title = "ADOBE XD";
        }
        else if (type == 4){
            _background = "#D8A6FF";
            _title = "FIGMA";
        }
        else{
            _title = null;
        }
        this.setState({
            background : _background,
            title : _title
        });
    }

	render(){
        let {type} = this.props;
        let {background, title} = this.state;

        return(
            <div>
                {title !== "" &&
                    (<div className="badge" style={{background: background}}  onClick={() => this.props.onClick()}>
                        {title}
                    </div>
                )}
            </div>
            
        );
    }
}

export default BADGE;

