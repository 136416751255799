import React from 'react';
import './App.scss';
//PLUGINS
import {isMobile} from 'react-device-detect';
//PAGES
import LoaderPage from "./Pages/LoaderPage/LoaderPage";
import MainPage from "./Pages/MainPage/MainPage";
import ApiService from "./Services/ApiService";
//COMPONENTS
import CURSOR from "./Components/CURSOR/CURSOR";

const apiService = new ApiService();


class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showNewPage : false,
            projects : null,
		};
	}

	componentDidMount() {
        this._getDatas();

		setTimeout(() => {
			this.setState({
				showNewPage : true
			});
		}, 2500);
	}

    /** Get the datas from api */
    async _getDatas(){
        let _projects = [];
        _projects = await apiService.getAllProjects();
		
        this.setState({
            projects : _projects
        })
    }


	render() {
        let {projects, showNewPage} = this.state;
		return(
			<div className="app">
                {!isMobile && <CURSOR />}
				{showNewPage ? 
					projects ? 
						<MainPage projects={projects} /> 
					: <LoaderPage showNewPage={projects!==null}/>
				: <LoaderPage showNewPage={showNewPage}/>}
			</div>
		);
	}
}

export default App;


