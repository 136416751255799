/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./PROJECT_ITEM.scss";

class PROJECT_ITEM extends Component {
	render(){
      let {label, img, mini, carousel} = this.props;

		return(
            <div className="project-item" style={{ height: mini===true ? '150px' : '200px', borderLeft:carousel===true ? '2px solid white' : '' }} onClick={() => this.props.onClick()}>
                <div className="project-item-content">
                    <div className="project-item-content-hover">
                        <p className="project-item-content-hover-text">{label}</p>
                        <p className="project-item-content-hover-icon">↙</p>
                        <div className="project-item-content-hover-cover"></div>
                    </div>
                    <div className="project-item-content-img" style={{ backgroundImage: `url(${img})` }}></div>
                </div>
            </div>
		);
	}
}

export default PROJECT_ITEM;

