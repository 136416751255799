import DATA from "../texts.json";


class StringService{

   getSelectedPrjType(prj, lang){
      let types = "";
      let i = 0;

      if(prj['types']){
         prj['types'].map((type) => {
            if(i < prj['types'].length-1) types = types+this.formatType(type, lang)+"  •  ";
            else if(i === prj['types'].length-1) types = types+this.formatType(type,lang);
            i++
         })
      }
      return types;
   }

   /** Render text */
   formatType(type, lang){
      let res =  "";
      if(type == 0){
         res = " "+DATA.techs.dg[lang]+" ";
      }else if (type == 1){
         res = " "+DATA.techs.webdesign[lang]+" ";
      }
      else if (type == 2){
         res = " "+DATA.techs.webdev[lang]+" ";
      }
      else if (type == 3){
         res = " "+DATA.techs.webmobile[lang]+" ";
      }
      return res;
  }

}

export default StringService;