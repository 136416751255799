import React from 'react';
import './LoaderPage.scss';
//IMGS
import { ReactComponent as LOGO } from "../../_imgs/LOGO_WHITE.svg";
import { ReactComponent as SIGLE } from "../../_imgs/SIGLE_WHITE.svg";

class LoaderPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    /** Display the LOGO */
    LOGO_BLACK() {
        return (
            <div className="logo">
                <LOGO width="200" height="100" />
            </div>
        );
    }

    /** Display the SIGLE */
    SIGLE() {
        return (
            <div className="logo">
                <SIGLE width="100" height="50" />
            </div>
        );
    }

    render() {
        return (
            <div className="loaderPage">
                {/* {this.LOGO_BLACK()} */}
                {this.SIGLE()}
            </div>
        );
    }

   
}

export default LoaderPage;


