/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./MINI_BTN.scss";

class MINI_BTN extends Component {

	render(){
      let {label} = this.props;

		return(
			<div className="mini-btn" onClick={() => this.props.onClick()}>
				<div className="mini-btn-icon">↘</div>
				<div className="mini-btn-title">{label}</div>
			</div>
		);
	}
}

export default MINI_BTN;

