/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./TITLE.scss";

class TITLE extends Component {
	render(){
        let {nbr, name} = this.props;
        return(
            <div className="title">
                <div className="title-nbr">{nbr}</div>
                <h2 className="title-name">{name}</h2>
            </div>
        );
    }
}

export default TITLE;

