import { collection, getDocs } from "firebase/firestore"; 
import { db, storage } from './FirebaseCloudService';
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";

class ApiService{

   //-----------------------------------------------//
   //------------------- GET -----------------------//
   //-----------------------------------------------//

   /* Get all projects */
   async getAllProjects(){
      let projects = [];

      const querySnapshot = await getDocs(collection(db, "projects"));
      querySnapshot.forEach(async (doc) => {
         let id = doc.id;

         let project = {};
         project = doc.data();
         project['imgs'] = [];
         let imgs = [];

         imgs = await this.loadImgs(id);  //get imgs
         project['imgs'] = imgs;

         projects.push(project);
      });
      projects.sort((a, b) => (a["id"] > b["id"]) ? 1 : -1)

      return projects;
   }

   /* load the imgs */
   async loadImgs(prjId){
      let imgs = [];
      let id = 0;
      const listRef = ref(storage, 'projects/'+prjId);
      listAll(listRef).then((res) => {
         res.items.forEach( async (itemRef) => {
            let img = {"id" : id, "url" : ""};
            getDownloadURL(itemRef).then((url) =>{
               img["url"] = url;
               imgs.push(img);
            });
            id++;
         });
      }).catch((error) => {
         // console.error(error) // Uh-oh, an error occurred!
      });
      imgs.sort((a, b) => (a["id"] > b["id"]) ? 1 : -1)
      return imgs;
  }
}

export default ApiService;