/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./BTN.scss";

class BTN extends Component {

	render(){
    	let {label, selected} = this.props;
		let className = "";
		selected ? className = "btn --selected" : className = "btn";
		return(
			<div className={className} onClick={() => this.props.onClick()}>
                {label}
			</div>
		);
	}
}

export default BTN;

