import React, { useRef, useEffect } from "react";
import "./CURSOR.scss";

function CURSOR() {
    const cursorRef = useRef(null);

    useEffect(() => {
        document.body.addEventListener("mousemove", (e) => {
            if(cursorRef!== null){
                cursorRef.current.style.setProperty(
                    "display", "flex"
                );
                cursorRef.current.style.setProperty(
                    "background-position", e.clientX - 10 + "px " + (e.clientY - 10) + "px"
                );
            }
        });
        document.body.addEventListener("mouseout", () => {
            if(cursorRef !== null) cursorRef.current.style.visibility = "hidden";
        });
        document.body.addEventListener("mouseover", () => {
            if(cursorRef !== null) cursorRef.current.style.visibility = "visible";
        });
    }, []);

    return <div className="cursor" ref={cursorRef}></div>;
}

export default CURSOR;