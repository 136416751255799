/** Natives **/
import React, { Component } from "react";
/** Style **/
import "./LANG.scss";
//DATA
import DATA from "../../texts.json";

class LANG extends Component {
	constructor(props) {
        super(props);
        this.state = {
            selectedType : 0,
        };
    }

	/** Select lang */
	_onSelectLang(selectedLang){
		let langID=null;
		langID = (selectedLang == 0 ? 1 : 0); 
		this.props.onClick(langID);
	}


	render(){
    	let {selectedLang} = this.props;
		return(
			<div className="lang" onClick={() => this._onSelectLang(selectedLang)}>
                {DATA.lang[selectedLang]}
			</div>
		);
	}
}

export default LANG;

