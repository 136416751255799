import React from 'react';
import './MainPage.scss';
//DATA
import DATA from "../../texts.json";
//IMGS
import { ReactComponent as LOGO } from "../../_imgs/LOGO_BLACK.svg";
import { ReactComponent as STUDIO } from "../../_imgs/STUDIO.svg";
import BG_VIDEO from "../../_imgs/VID4.mp4";
//COMPONENTS
import LANG from "../../Components/LANG/LANG";
import TITLE from "../../Components/TITLE/TITLE";
import MINI_BTN from "../../Components/MINI_BTN/MINI_BTN";
import MARQUEE from "../../Components/MARQUEE/MARQUEE";
import CREATIONS from "../../Components/CREATIONS/CREATIONS";

class MainPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedLang : null,
            selectedProject : {},
            selectedPic : "",
        };
    }

    componentDidMount() {
        let {projects} = this.props;

        projects.forEach( async (project) => {
            project["imgs"].sort((a, b) => (a["id"] > b["id"]) ? 1 : -1)
        });

        let lang;
        let selectedLangStorage = localStorage.getItem('selectedLang');

        if(selectedLangStorage === undefined || selectedLangStorage === null ){
            localStorage.setItem('selectedLang', 0);
            lang = 0;
        }
        else lang = selectedLangStorage
        
        this.setState({
            selectedLang    : lang,
            selectedProject : projects[0],
            selectedPic     : projects[0]['imgs'][0]
        });
	}

    /** Scroll to contact */
    _goToContacts(){
        let pageBottom = document.querySelector("#contacts")
        pageBottom.scrollIntoView()
    }

    /** Redirect to a link */
    _redirectLinks = (value) => {
             if(value === "ln")      window.open("https://www.linkedin.com/in/alexis-bardonnet-968a99172/","_blank");
        else if(value === "behance") window.open("https://www.behance.net/alexisbardonnet","_blank");
        else if(value === "tel")     window.location.href = "tel:+33606473652";
        else if(value === "mail")    window.location.href = "mailto:alexis@audacieux.studio";
        else if(value === "malt")    window.open("https://www.malt.fr/profile/alexisbardonnet","_blank");
        else if(value === "insta")   window.open("https://www.instagram.com/audacieux.studio/","_blank");
    };

    /** Select the language of the website */
    _onSelectLang(langID){
        this.setState({
            selectedLang : langID
        });
        localStorage.setItem('selectedLang', langID);
    }

    /** On clcik on project */
    _onSelectProject(prj){
        this.setState({
            selectedProject : prj,
            selectedPic : prj['imgs'][0]
        })
    }

    /** On selecting pic */
    _onSelectPicture(pic){
        this.setState({
            selectedPic : pic
        })
    }


    //------------------------------------------------------------//
	//------------------------ RENDER ----------------------------//
   //------------------------------------------------------------//


    render() {
        let {projects} = this.props;
        let {selectedProject, selectedPic, selectedLang} = this.state;
        
        return (
            <div className='mainPage'>
                <div className='mainPage-content'>
                    <div id="logo" className="logo" onClick={() => {this._goToContacts()}}>
                        <LOGO className="logo-item" />
                    </div>
                    <header className="header">
                        <div className="header-data">
                            <div className="header-data-specialities">
                                <div className="header-specialities-item">→&ensp;{DATA.techs.dg[selectedLang]}</div>
                                <div className="header-specialities-item">→&ensp;{DATA.techs.webdesign[selectedLang]}</div>
                                <div className="header-specialities-item">→&ensp;{DATA.techs.dev[selectedLang]}</div>
                            </div>
                            {selectedLang !== null && <LANG selectedLang={selectedLang} onClick={(langID) => this._onSelectLang(langID)}/>}
                        </div>
                        <div className="header-studio">
                            <STUDIO className="header-studio-title"/>
                        </div>

                        <div className="header-filter"></div>
                        <video 
                            className="header-image" 
                            autoPlay 
                            muted 
                            loop 
                            playsInline
                            id="video">
                            <source src={BG_VIDEO} type="video/mp4" />
                        </video>
                    </header>

                    {/* PHILOSPHY */}
                    <div className="philosophy">
                        <div className="philosophy-title">
                            <TITLE nbr="①" name={DATA.titles.philophy[selectedLang]} />
                        </div>
                        <div className="philosophy-text">
                            {DATA.texts.philophy[selectedLang]}
                        </div>
                    </div>

                    {/* KNOWLEDGES */}
                    {selectedLang !== null &&(<div className="knowledges">
                        <div className="knowledges-texts">
                            <div className="knowledges-texts-title">
                                <TITLE nbr="②" name={DATA.titles.knowledges[selectedLang]} />
                            </div>
                            <div className="knowledges-texts-text">
                                {DATA.texts.knowledges[selectedLang]}
                            </div>
                        </div>
                        <div className="knowledges-list">
                            <MARQUEE direction="left"  speed="50" size="big" name={DATA.techs.dg[selectedLang]} extra="dg"/>
                            <MARQUEE direction="right" speed="50" size="big" name={DATA.techs.webdesign[selectedLang]}  extra="wd"/>
                            <MARQUEE direction="left"  speed="50" size="big" name={DATA.techs.dev[selectedLang]} extra="dev"/>
                        </div>
                    </div>)}

                    {/* CREATIONS */}
                    {selectedLang !== null && selectedProject !== {} && (
                        <CREATIONS 
                            projects={projects} 
                            selectedProject={selectedProject} 
                            selectedLang={selectedLang}
                            selectedPic={selectedPic}
                            onSelectPrj={(prj) =>{this._onSelectProject(prj)}}
                            onSelectPic={(pic) =>{this._onSelectPicture(pic)}}
                        />
                    )}

                    {/* CONTACTS */}
                    {selectedLang !== null && (<div className="contacts" id="contacts">
                        <div className="contacts-texts">
                            <div className="contacts-texts-title">
                                <TITLE nbr="④" name="Contacts" />
                            </div>
                            <div className="contacts-texts-btns">
                                <MINI_BTN label="alexis@audacieux.studio"  onClick={() => this._redirectLinks("mail")}/>
                                <MINI_BTN label="+33 6 06 47 36 52"        onClick={() => this._redirectLinks("tel")}/>
                                <MINI_BTN label="instagram"                onClick={() => this._redirectLinks("insta")}/>
                                <MINI_BTN label="behance"                  onClick={() => this._redirectLinks("behance")}/>
                                <MINI_BTN label="linkedin"                 onClick={() => this._redirectLinks("ln")}/>
                                <MINI_BTN label="malt"                     onClick={() => this._redirectLinks("malt")}/>
                            </div>
                        </div>
                        <div className="contacts-credits">
                            <div className="contacts-credits-line">{DATA.texts.footer_rights[selectedLang]}</div>
                            <div className="contacts-credits-line">{DATA.texts.footer_author[selectedLang]}</div>
                        </div>
                    </div>)}

                </div>
                <div className='mainPage-blank'></div>
            </div>
        );
    }
}


export default MainPage;


